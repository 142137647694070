/* eslint-disable react/prop-types */

import React, { useEffect, useRef, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import Img from "gatsby-image";
import Button from "~components/Button";

const query = graphql`
  query GivePaireCTA {
    sanityGivePaire {
      heading
      subheading
      content
      image {
        asset {
          fluid(maxWidth: 1024) {
            ...GatsbySanityImageFluid
          }
        }
      }
    }
  }
`;

const GivePaireCTA = ({ subheading, heading, image, content }) => {
  const submitRef = useRef();

  const [username, setUsername] = useState(null);
  const [recipientName, setRecipientName] = useState(null);
  const [recipientPhone, setRecipientPhone] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [valid, setValid] = useState(false);
  const [phoneValid, setPhoneValid] = useState(false);
  const [phoneInteracted, setPhoneInteracted] = useState(false);

  const url = `https://us-central1-paire-3faa1.cloudfunctions.net/sendSMS`;

  //

  const submitProxy = e => {
    e.preventDefault();

    if (submitRef?.current) {
      submitRef.current.click();
    }

    return false;
  };

  const onSubmit = e => {
    e.preventDefault();

    if (!valid || submitting || submitted) {
      return false;
    }

    setSubmitting(true);

    fetch(url, {
      body: JSON.stringify({
        username,
        recipientPhone: `+61${recipientPhone}`,
        recipientName
      }),
      headers: new Headers({
        "Content-Type": `application/json`
      }),
      method: `POST`
    }).then(response => {
      console.log(`Free Paire request sent: `, response);

      setSubmitting(false);
      setSubmitted(true);
    });

    return false;
  };

  useEffect(() => {
    setPhoneValid(recipientPhone?.length === 9);
  }, [recipientPhone]);

  useEffect(() => {
    setValid(username && recipientName && phoneValid);
  }, [username, recipientName, phoneValid]);

  //

  let submitText = `Share a paire`;

  if (submitting) {
    submitText = `Sending...`;
  } else if (submitted) {
    submitText = `Sent!`;
  }

  //

  return (
    <StaticQuery
      query={query}
      render={data => {
        const cms = data.sanityGivePaire;

        //

        return (
          <section className="give-cta w-full relative pt-v4 pb-v4 bg-picasso text-black xs:min-h-screen xs:py-10">
            <article className="grid items-center">
              <div className="grid-end-4 xs:grid-end-12 grid-start-2 xs:grid-start-1 relative">
                <h3 className="mb-v1 xs:mb-v4 caption text-center">
                  {subheading || cms.subheading}
                </h3>
                <h2 className="f1 text-center">{heading || cms.heading}</h2>

                <p className="mt-v3 xs:mt-v8 mb-v2 xs:mb-v8 b2 text-center">
                  {content || cms.content}
                </p>

                <form
                  className={`w-full relative ${
                    submitted || submitting
                      ? `opacity-25 pointer-events-none`
                      : ``
                  }`}
                  onSubmit={onSubmit}
                >
                  <div className="w-full relative flex xs:block mt-v1">
                    <input
                      className="button xs:w-full mr-v05 xs:mt-v3 bg-transparent text-center border-black rounded-edge--more overflow-hidden f-button xs:py-4"
                      onChange={e => setUsername(e.currentTarget.value)}
                      placeholder="YOUR NAME"
                      type="text"
                    />

                    <input
                      className="button xs:w-full ml-v05 xs:mt-v3 bg-transparent text-center border-black rounded-edge--more overflow-hidden f-button xs:py-4"
                      onChange={e => setRecipientName(e.currentTarget.value)}
                      placeholder="Friend’s Name"
                      type="text"
                    />
                  </div>

                  <div className="w-full relative flex mt-v1 xs:mt-v3 xs:py-4">
                    <div className="button w-24 mr-2 bg-transparent text-center border-black rounded-edge--more overflow-hidden f-button">
                      +61
                    </div>

                    <input
                      className={`button w-full text-center rounded-edge--more overflow-hidden f-button ${
                        phoneInteracted && !phoneValid
                          ? `button--red text-red`
                          : `border-black`
                      } bg-transparent`}
                      onChange={e => {
                        if (!phoneInteracted) {
                          setPhoneInteracted(true);
                        }

                        setRecipientPhone(e.currentTarget.value);
                      }}
                      placeholder="Friend’s Number"
                      type="number"
                    />
                  </div>

                  {/* // */}

                  <input
                    ref={submitRef}
                    className="w-0 h-0 absolute opacity-0 pointer-events-none"
                    type="submit"
                  />

                  <Button
                    className={`w-full relative block mt-v1 xs:mt-v3 xs:py-4 ${
                      !valid ? `opacity-25` : ``
                    } ${
                      !valid || submitting || submitted
                        ? `pointer-events-none`
                        : ``
                    }`}
                    color="black"
                    onClick={submitProxy}
                    transparent
                  >
                    <span className="f-button">{submitText}</span>
                  </Button>
                </form>
              </div>

              <figure className="grid-end-6 xs:grid-end-12 grid-start-7 xs:grid-start-1 xs:mt-v12 xs:mb-v12">
                {cms?.image?.asset?.fluid && (
                  <Img
                    className="w-full relative block"
                    fluid={image?.asset?.fluid || cms.image.asset.fluid}
                    alt={image?.altText || cms.image.altText}
                  />
                )}
              </figure>
            </article>
          </section>
        );
      }}
    />
  );
};

export default GivePaireCTA;
