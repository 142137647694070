import React from "react";
import PropTypes from "prop-types";

const LogoClip = ({ className }) => (
  <svg className={className} viewBox="0 0 750 156" fill="none">
    <path
      d="M89.0315 -17.8443C91.1909 -19.3847 93.8283 -21.2371 96.8172 -23.2938L96.3786 -23.6642C93.4151 -22.6685 90.2437 -22.4612 87.1763 -23.0625C84.1088 -23.6637 81.2509 -25.053 78.8834 -27.0936C73.0987 -31.9782 72.8081 -37.7346 77.1893 -42.8383C83.8396 -50.7138 97.5662 -54.26 105.602 -47.2958C107.729 -45.5887 109.358 -43.3402 110.317 -40.7862C111.277 -38.2322 111.53 -35.4668 111.052 -32.7804C111.439 -33.0265 111.909 -33.202 112.331 -33.4898C114.103 -34.5707 115.936 -35.5986 117.77 -36.6266C119.223 -34.7691 120.797 -33.0098 122.481 -31.3602C111.963 -26.0538 101.9 -19.8894 92.3939 -12.9295C85.622 -8.34156 77.1947 -4.29267 67.2799 -12.8439C60.3676 -18.6808 60.0788 -24.1852 62.7986 -27.3637C63.2621 -28.0098 63.8608 -28.547 64.5531 -28.9381C65.2454 -29.3291 66.0146 -29.5644 66.8071 -29.6277C67.5996 -29.691 68.3962 -29.5807 69.1416 -29.3044C69.887 -29.0282 70.5631 -28.5928 71.1228 -28.0284C71.6636 -27.6727 72.123 -27.2066 72.4709 -26.6607C72.8189 -26.1148 73.0474 -25.5015 73.1417 -24.861C73.2359 -24.2205 73.1936 -23.5673 73.0177 -22.9442C72.8417 -22.3211 72.536 -21.7422 72.1206 -21.2455C71.6706 -20.6342 71.0736 -20.1463 70.385 -19.827C69.6964 -19.5077 68.9384 -19.3673 68.1813 -19.4188C68.8086 -18.4705 69.58 -17.6259 70.4677 -16.9155C75.7655 -12.5135 81.3676 -12.7929 89.0315 -17.8443ZM107.32 -31.0294C107.528 -33.2438 107.181 -35.475 106.312 -37.5216C105.442 -39.5682 104.076 -41.3657 102.337 -42.7521C96.8867 -47.3547 87.3972 -44.6322 83.0865 -39.6121C80.4582 -36.4994 80.4865 -33.398 84.3113 -30.1324C89.7122 -25.4287 96.2406 -25.5342 107.351 -30.8962L107.32 -31.0294Z"
      fill="black"
    />
    <path
      d="M156.636 10.5092C150.59 19.1097 136.361 23.3772 123.298 14.0639C113.927 7.36777 112.879 -0.710654 118.03 -7.95513C124.139 -16.5027 138.221 -20.8937 151.458 -11.3622C160.697 -4.84894 162.007 3.34315 156.636 10.5092ZM124.05 -4.19606C120.319 1.02724 121.724 5.97163 127.194 9.87423C130.765 12.4622 135.189 13.5907 139.564 13.0299C143.939 12.4691 147.937 10.2612 150.742 6.85596C154.472 1.63266 152.767 -3.42216 147.388 -7.17717C143.884 -9.83859 139.475 -11.0174 135.11 -10.4598C130.746 -9.90227 126.774 -7.65293 124.05 -4.19606Z"
      fill="black"
    />
    <path
      d="M203.557 38.5703C198.351 47.7011 184.562 53.2701 170.692 45.2083C160.738 39.415 158.943 31.4579 163.415 23.7471C168.705 14.6868 182.309 8.99764 196.391 17.2743C206.259 22.9585 208.127 30.8706 203.557 38.5703ZM169.736 26.9375C166.518 32.486 168.376 37.2758 174.188 40.6451C177.979 42.902 182.49 43.6249 186.798 42.6659C191.105 41.7068 194.884 39.1383 197.362 35.4857C200.58 29.9371 198.443 25.0546 192.738 21.8121C188.997 19.488 184.493 18.7229 180.193 19.6811C175.893 20.6392 172.139 23.2443 169.736 26.9375Z"
      fill="black"
    />
    <path
      d="M254.163 37.8236C251.664 36.9316 249.002 36.5876 246.358 36.815C246.707 34.8138 246.829 32.7796 246.72 30.7512C250.733 30.8983 254.692 31.7247 258.429 33.1953C262.826 35.1904 264.799 38.5737 262.749 43.0341C259.606 49.9343 241.548 63.5655 238.618 70.0015C237.78 71.7988 238.227 72.9638 239.822 73.702C241.877 74.6146 244.274 74.2763 248.373 72.0481C248.496 73.9137 248.866 75.7548 249.474 77.5226C244.246 79.4776 239.88 80.1569 235.466 78.1827C234.553 77.8713 233.722 77.36 233.032 76.6862C232.343 76.0125 231.812 75.1933 231.479 74.2883C231.147 73.3832 231.02 72.4153 231.109 71.4551C231.198 70.4948 231.5 69.5665 231.994 68.7378C232.681 67.3549 233.477 66.0291 234.376 64.773L233.849 64.5072C226.089 70.2649 219.479 71.0175 213.649 68.3483C207.818 65.679 205.938 59.9405 208.854 53.5639C213.816 42.6884 227.972 34.5665 238.804 39.4903C241.204 40.5023 243.216 42.2584 244.543 44.5C245.871 46.7416 246.443 49.3504 246.176 51.9423C249.714 48.8158 252.867 45.2802 255.571 41.4099C255.751 41.0883 255.858 40.7307 255.884 40.3628C255.909 39.9948 255.852 39.6259 255.718 39.2827C255.583 38.9394 255.373 38.6304 255.105 38.3781C254.836 38.1258 254.514 37.9364 254.163 37.8236ZM242.909 54.8373C242.289 49.5549 240.582 46.7892 237.274 45.2841C229.563 41.7068 219.292 48.2782 215.996 55.4434C214.116 59.6179 215.04 62.2945 218.55 63.899C223.436 66.1632 232.22 64.7419 242.909 54.8373Z"
      fill="black"
    />
    <path
      d="M340.501 82.6715C348.919 84.7342 357.572 86.8158 360.246 87.2493C359.108 89.0673 358.152 90.9935 357.394 92.9997C354.945 92.2561 346.755 90.1354 338.554 88.1127C336.281 94.5318 332.735 104.027 332.184 105.315C327.083 118.685 320.094 122.302 312.109 120.319C307.294 119.116 304.625 116.218 305.671 112.056C305.8 111.329 306.084 110.638 306.504 110.031C306.924 109.423 307.47 108.913 308.105 108.536C308.739 108.158 309.448 107.922 310.182 107.842C310.916 107.763 311.658 107.843 312.359 108.077C313.087 108.225 313.776 108.529 314.377 108.966C314.978 109.404 315.479 109.966 315.844 110.613C316.209 111.261 316.431 111.98 316.495 112.721C316.558 113.462 316.462 114.209 316.212 114.909C316.174 114.996 316.156 115.091 316.158 115.185C319.779 114.414 322.991 111.329 325.733 104.269C326.656 101.863 329.904 92.9757 332.236 86.5709C326.128 84.992 320.753 83.8167 318.942 83.5042C320.066 81.6783 321.021 79.7535 321.794 77.7538C323.393 78.317 328.34 79.703 334.26 81.1232C334.513 80.4423 334.705 79.9604 334.777 79.6634C339.512 67.2358 347.337 63.1796 356.964 65.5474C362.632 66.9695 365.677 70.1847 364.659 74.2281C364.501 74.9536 364.196 75.6392 363.763 76.2431C363.331 76.847 362.78 77.3566 362.145 77.7407C361.509 78.1248 360.802 78.3754 360.066 78.4772C359.331 78.579 358.582 78.5299 357.866 78.3328C357.137 78.2297 356.439 77.9702 355.819 77.572C355.199 77.1739 354.673 76.6465 354.277 76.026C353.88 75.4056 353.622 74.7066 353.52 73.9771C353.419 73.2475 353.476 72.5046 353.689 71.7992C353.798 71.3767 353.952 70.967 354.148 70.577C348.388 69.8999 343.875 73.2105 340.629 82.3503L340.501 82.6715Z"
      fill="black"
    />
    <path
      d="M404.477 112.65C405.25 114.397 406.163 116.078 407.209 117.677C400.371 121.807 392.237 123.235 384.403 121.68C371.869 119.577 366.982 112.909 368.39 104.293C370.259 92.8451 381.192 83.5406 397.745 86.138C407.401 87.7079 411.196 92.916 410.298 98.3848C408.879 107.099 397.238 109.615 375.27 105.594C374.652 111.621 378.602 114.992 386.668 116.328C392.856 117.494 399.255 116.173 404.477 112.65ZM376.319 101.47C394.292 104.122 402.581 103.033 403.431 97.9177C403.942 94.7706 401.31 92.5487 395.955 91.6046C386.553 90.0713 379.078 94.6022 376.319 101.47Z"
      fill="black"
    />
    <path
      d="M459.317 118.627C460.194 120.328 461.22 121.949 462.382 123.47C455.835 127.997 447.834 129.919 439.946 128.858C427.392 127.668 422.006 121.295 422.844 112.592C423.929 101.055 434.202 91.0493 450.824 92.632C460.471 93.6208 464.684 98.5025 464.21 103.935C463.378 112.716 451.855 115.94 429.765 113.462C429.557 119.512 433.719 122.633 441.851 123.381C448.071 124.148 454.343 122.441 459.317 118.627ZM430.468 109.367C448.571 110.805 456.776 109.181 457.273 104.017C457.637 100.747 454.83 98.734 449.394 98.2234C439.89 97.3195 432.748 102.346 430.468 109.367Z"
      fill="black"
    />
    <path
      d="M497.885 126.968C503.067 127.229 509.339 124.08 516.48 115.115C517.82 116.627 519.294 118.016 520.883 119.263C511.541 129.267 504.469 133.279 495.861 132.881C488.897 132.655 485.138 129.481 485.424 123.424C485.955 113.136 498.658 97.6683 498.946 91.184C499.024 89.5674 498.19 88.6485 496.223 88.5625C492.777 88.4076 488.541 90.6279 481.071 95.915C480.191 94.1352 479.165 92.4311 478.004 90.82C486.46 85.0422 493.519 82.0207 499.342 82.2852C500.316 82.2048 501.295 82.3411 502.21 82.6844C503.124 83.0276 503.952 83.5692 504.632 84.2703C505.312 84.9714 505.829 85.8146 506.145 86.7392C506.46 87.6639 506.567 88.647 506.458 89.6181C506.048 98.5763 493.045 114.865 492.693 122.441C492.585 125.069 494.151 126.857 497.885 126.968Z"
      fill="black"
    />
    <path
      d="M550.362 118.286C539.802 116.811 535.172 114.118 535.232 108.158C535.288 101.441 542.569 97.1397 554.741 97.2555C566.914 97.3713 571.037 102.105 570.98 106.495C571.01 107.184 570.891 107.872 570.631 108.512C570.37 109.151 569.974 109.727 569.47 110.199C568.966 110.671 568.366 111.028 567.711 111.246C567.056 111.464 566.362 111.538 565.676 111.463C565.062 111.554 564.436 111.513 563.839 111.343C563.242 111.173 562.688 110.879 562.214 110.478C561.74 110.078 561.357 109.581 561.089 109.021C560.822 108.461 560.677 107.851 560.664 107.231C560.633 106.498 560.813 105.772 561.183 105.139C561.552 104.506 562.096 103.993 562.749 103.659C559.963 102.939 557.097 102.575 554.219 102.576C545.092 102.455 541.622 104.785 541.534 107.897C541.447 111.008 543.464 111.924 551.84 113.02C561.967 114.414 567.991 116.388 567.917 123.34C567.843 130.291 561.167 134.638 547.822 134.427C533.805 134.293 527.597 129.909 527.651 123.871C527.62 123.137 527.748 122.403 528.027 121.723C528.307 121.042 528.731 120.43 529.269 119.929C529.808 119.428 530.449 119.05 531.148 118.82C531.847 118.591 532.588 118.516 533.318 118.601C533.913 118.526 534.516 118.581 535.088 118.759C535.659 118.938 536.186 119.238 536.631 119.638C537.077 120.038 537.432 120.53 537.671 121.079C537.91 121.628 538.029 122.222 538.019 122.821C538.069 123.714 537.776 124.593 537.198 125.277C536.621 125.961 535.803 126.398 534.914 126.497C539.084 128.353 543.612 129.268 548.176 129.179C556.904 129.392 561.531 127.216 561.565 123.702C561.444 120.88 559.175 119.5 550.362 118.286Z"
      fill="black"
    />
    <path
      d="M659.857 104.317C668.541 104.243 677.429 104.054 680.123 103.788C679.503 105.846 679.063 107.953 678.81 110.087C676.259 109.973 667.803 109.989 659.335 110.104C658.814 116.821 657.711 126.982 657.516 128.357C655.923 142.614 650.078 147.841 641.824 147.957C636.881 148.006 633.577 145.86 633.525 141.558C633.466 140.821 633.566 140.08 633.818 139.386C634.071 138.691 634.471 138.059 634.99 137.533C635.509 137.006 636.135 136.598 636.827 136.336C637.518 136.074 638.257 135.964 638.995 136.013C639.736 135.979 640.477 136.102 641.167 136.374C641.858 136.647 642.483 137.063 643 137.595C643.518 138.127 643.917 138.762 644.171 139.46C644.425 140.158 644.528 140.901 644.473 141.642C644.458 141.738 644.459 141.836 644.478 141.932C647.793 140.294 650.233 136.557 651.03 128.963L652.863 110.185C646.506 110.257 641.028 110.39 639.199 110.564C639.835 108.515 640.281 106.412 640.533 104.282C642.191 104.394 647.371 104.403 653.426 104.434C653.48 103.692 653.554 103.182 653.549 102.891C654.945 89.7564 661.559 83.7463 671.482 83.6448C677.314 83.5593 681.064 85.9389 681.103 90.087C681.132 90.827 681.011 91.5653 680.746 92.257C680.481 92.9487 680.079 93.5795 679.563 94.1111C679.047 94.6426 678.429 95.0639 677.746 95.3494C677.062 95.6348 676.328 95.7784 675.587 95.7715C674.857 95.8564 674.118 95.7829 673.419 95.5561C672.72 95.3292 672.078 94.9542 671.537 94.4568C670.996 93.9593 670.569 93.3511 670.285 92.6735C670 91.9959 669.865 91.2649 669.889 90.5303C669.905 90.0853 669.945 89.6415 670.01 89.201C664.257 89.9975 660.742 94.3296 659.828 103.971L659.857 104.317Z"
      fill="black"
    />
    <path
      d="M727.968 121.394C728.964 122.978 730.096 124.473 731.351 125.861C725.25 130.944 717.485 133.591 709.552 133.29C696.867 133.315 690.956 127.393 690.997 118.66C691.05 107.074 700.335 96.1632 717.083 96.2416C726.844 96.2896 731.417 100.831 731.397 106.361C731.363 115.171 720.284 119.522 697.971 119.002C698.305 125.046 702.742 127.755 710.912 127.782C717.215 127.976 723.342 125.681 727.968 121.394ZM698.376 114.836C716.491 114.566 724.557 112.216 724.567 107.035C724.589 103.832 721.661 102.004 716.208 101.98C706.66 101.933 700.017 107.597 698.376 114.836Z"
      fill="black"
    />
    <path
      d="M781.982 122.882C782.946 124.5 784.042 126.036 785.259 127.474C779.037 132.369 771.23 134.797 763.331 134.294C750.723 133.846 744.956 127.796 745.288 119.057C745.708 107.46 755.369 96.8664 772.064 97.473C781.813 97.8324 786.244 102.541 786.05 108.066C785.746 116.899 774.394 120.769 752.2 119.598C752.342 125.658 756.695 128.511 764.848 128.811C771.114 129.154 777.266 127.025 781.982 122.882ZM752.614 115.296C770.763 115.663 778.87 113.562 779.055 108.386C779.192 105.173 776.289 103.188 770.865 103.045C761.371 102.722 754.496 108.153 752.635 115.313L752.614 115.296Z"
      fill="black"
    />
  </svg>
);

LogoClip.defaultProps = {
  className: ``
};

LogoClip.propTypes = {
  className: PropTypes.string
};

export default LogoClip;
