/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { useContext, useEffect, useRef, useState } from "react";
import { graphql, Link } from "gatsby";
import Img from "gatsby-image";
import { AppContext } from "~context/AppContext.jsx";
import { DocumentContext } from "~context/DocumentContext";
import Button from "~components/Button";
import Footer from "~components/Footer";
import GivePaireCTA from "~components/GivePaireCTA";
import Layout from "~components/Layout";
import SEO from "~components/SEO";
import SizeChart from "~components/SizeChart.jsx";
import LogoClip from "~components/svg/LogoClip";
import SquiggleLine from "~components/svg/SquiggleLine";
import { getVariantByOptions } from "~utils/shopify";

const ProductsPage = ({ data, location }) => {
  const {
    addToCart,
    inventoryCache,
    inventoryEnabled,
    inventoryFetched
  } = useContext(AppContext);
  const { device, windowWidth } = useContext(DocumentContext);

  const filterRef = useRef();

  const [addEnabledProducts, setAddEnabledProducts] = useState({});
  const [chart, setChart] = useState(false);

  //

  const cms = data.sanityProductsPage;

  const products = {};
  const { jumpTargets } = cms;

  jumpTargets.forEach(jumpTarget => {
    const ref = useRef();
    const productGroup = [];

    products[jumpTarget] = [];

    if (data?.allSanityProduct?.edges?.[0]) {
      data.allSanityProduct.edges.forEach(({ node }) => {
        if (node.productType.toLowerCase() === jumpTarget.toLowerCase()) {
          productGroup.push(node);
        }
      });
    }

    productGroup.sort((a, b) => {
      const priorityA = a.priority;
      const priorityB = b.priority;

      if (priorityA < priorityB) {
        return -1;
      }

      if (priorityA > priorityB) {
        return 1;
      }

      return 0;
    });

    products[jumpTarget] = {
      ref,
      products: productGroup
    };
  });

  //

  const enableProductAdd = (handle, colour) => {
    const enabled = JSON.parse(JSON.stringify(addEnabledProducts));

    enabled[handle] = colour;

    setAddEnabledProducts(enabled);
  };

  const jumpTo = key => {
    if (!window || typeof window === `undefined`) {
      return;
    }

    const { ref } = products[key];

    if (!ref?.current) {
      return;
    }

    const top =
      ref.current.offsetTop -
      (device === `desktop`
        ? windowWidth * 0.05
        : 42 - filterRef.current.getBoundingClientRect().height);

    window.scrollTo({
      top,
      behavior: `smooth`
    });
  };

  const parseToId = key => {
    return key
      .toLowerCase()
      .replace(/ /g, `-`)
      .replace(/’/g, `-`);
  };

  //

  return (
    <>
      <SEO customTitle={cms.title} path={location.pathname} />

      <Layout className="products-page w-full relative">
        <SizeChart chart={chart} setChart={setChart} />

        {device === `desktop` && (
          <div className="animation-appear-down-slow animation-delay-2 w-full absolute top-0 right-0 flex items-end justify-end">
            <LogoClip className="w-1/2" />
          </div>
        )}

        <section className="relative pb-v6 xs:pb-24">
          <div className="grid">
            <div
              ref={filterRef}
              className="animation-appear-left-slow animation-delay-1 grid-end-3 xs:grid-end-12 h-screen xs:h-auto sticky xs:relative top-0 xs:top-auto left-0 xs:left-auto pt-v12 xs:pt-32"
            >
              <h1 className={`${device === `desktop` ? `f3` : `f3`}`}>
                {cms.title}
              </h1>

              <SquiggleLine className="w-full mt-v1 xs:mt-6 mb-v2 xs:mb-8" />

              {jumpTargets?.[0] && (
                <>
                  <h3 className="mb-v05 xs:mb-3 b2 font-bold">Jump to:</h3>

                  <ul className="xs:w-full relative xs:flex xs:flex-no-wrap xs:overflow-x-scroll xs:whitespace-pre">
                    {jumpTargets.map(jumpTarget => {
                      return (
                        <li key={jumpTarget}>
                          <button
                            type="button"
                            onClick={() => jumpTo(jumpTarget)}
                            className={`relative block pt-v05 xs:pt-2 pb-v05 xs:pb-2 xs:mr-6 ${
                              device === `desktop` ? `caption` : `b2`
                            } hover-underline`}
                          >
                            {jumpTarget}
                          </button>
                        </li>
                      );
                    })}
                  </ul>

                  <button
                    type="button"
                    className={`mt-v1 xs:mt-2 ${
                      device === `desktop` ? `caption` : `b2`
                    } cursor-pointer underline`}
                    onClick={() => setChart(true)}
                  >
                    Unisex Size Chart
                  </button>
                </>
              )}
            </div>

            <div className="animation-appear-right-slow animation-delay-1 grid-end-9 xs:grid-end-12 relative pt-v12">
              {jumpTargets.map(productKey => {
                const productGroup = products[productKey];

                return (
                  <section
                    id={parseToId(productKey)}
                    ref={productGroup.ref}
                    className="relative flex flex-wrap mb-v4"
                  >
                    {productGroup.products.map((product, productIndex) => {
                      const key = product.handle;

                      if (!product?.shopifyProduct?.variants?.[0]) {
                        return <React.Fragment key={key}></React.Fragment>;
                      }

                      const isFeature = productIndex === 0;

                      //
                      // variant buttons

                      const colourButtons = [];
                      const sizeButtons = [];

                      product.shopifyProduct.variants.forEach(variant => {
                        const options = variant.selectedOptions;

                        options.forEach(option => {
                          switch (option.name.toLowerCase()) {
                            case `color`:
                            case `colour`:
                              if (!colourButtons.includes(option.value)) {
                                colourButtons.push(option.value);
                              }

                              break;

                            case `size`:
                              if (!sizeButtons.includes(option.value)) {
                                sizeButtons.push(option.value);
                              }

                              break;

                            default:
                              break;
                          }
                        });
                      });

                      //
                      // variant buttons (colours)

                      const colourButtonJSX = [];

                      let backgroundStyle = {};

                      colourButtons.forEach(colourValue => {
                        const selected =
                          addEnabledProducts?.[product.handle] === colourValue;

                        let hexes;

                        data.allSanityColourMap.edges.forEach(({ node }) => {
                          if (hexes) {
                            return;
                          }

                          if (
                            node.title.toLowerCase() ===
                            colourValue.toLowerCase()
                          ) {
                            ({ hexes } = node);
                          }
                        });

                        //

                        // TODO : auto-detect based on array length
                        if (hexes?.[0]) {
                          if (hexes.length === 3) {
                            backgroundStyle = {
                              background: `linear-gradient(to right, ${hexes[0]} 33.333%, ${hexes[1]} 33.333%, ${hexes[1]} 66.667%, ${hexes[2]} 66.667%)`
                            };
                          } else if (hexes.length === 2) {
                            backgroundStyle = {
                              background: `linear-gradient(to right, ${hexes[0]} 50%, ${hexes[1]} 50%)`
                            };
                          } else {
                            backgroundStyle = {
                              backgroundColor: hexes[0]
                            };
                          }
                        }

                        colourButtonJSX.push(
                          <button
                            key={`${product.handle}-${colourValue}`}
                            type="button"
                            className={`product-page__color-picker w-8 h-8 mr-2 relative block rounded-full ${
                              device === `desktop` && selected
                                ? `border-black`
                                : ``
                            }`}
                            onClick={() =>
                              enableProductAdd(product.handle, colourValue)
                            }
                          >
                            <div
                              className="product-page__color-picker__bg w-full h-full relative rounded-full overflow-hidden pointer-events-none"
                              style={backgroundStyle}
                            ></div>
                          </button>
                        );
                      });

                      //
                      // variant buttons (sizes)

                      const sizeButtonJSX = [];

                      sizeButtons.forEach(sizeValue => {
                        let buttonText;

                        switch (sizeValue.toLowerCase()) {
                          case `s`:
                            buttonText = `SML`;
                            break;

                          case `m`:
                            buttonText = `MED`;
                            break;

                          case `l`:
                            buttonText = `LRG`;
                            break;

                          case `xl`:
                          default:
                            buttonText = `XLG`;

                            break;
                        }

                        let variantForSize;
                        let inStock = false;

                        if (
                          addEnabledProducts?.[product.handle] &&
                          inventoryFetched
                        ) {
                          variantForSize = getVariantByOptions(
                            product.shopifyProduct,
                            {
                              Colour: addEnabledProducts?.[product.handle],
                              Size: sizeValue
                            }
                          );

                          inStock =
                            !inventoryEnabled ||
                            (variantForSize?.id &&
                              inventoryCache?.[variantForSize.id] &&
                              inventoryCache[variantForSize.id] > 0);
                        }

                        sizeButtonJSX.push(
                          <Button
                            className={`${
                              inStock ? `` : `opacity-25 pointer-events-none`
                            } relative block`}
                            onClick={() => {
                              if (variantForSize) {
                                addToCart({
                                  product: product.shopifyProduct,
                                  variant: variantForSize,
                                  variants: null
                                });
                              }
                            }}
                            color="hit-pink"
                            transparent
                          >
                            <span
                              className={`${
                                device === `desktop` ? `f-button` : `b2 italic`
                              }`}
                            >
                              {buttonText}
                            </span>
                          </Button>
                        );
                      });

                      //
                      // button-based image parsing

                      let image;
                      let imageType = `default`;

                      if (!addEnabledProducts?.[product.handle]) {
                        // if the user hasn't clicked a colour button in this item
                        if (product?.image?.asset?.fluid) {
                          image = product.image.asset.fluid;
                          imageType = `fluid`;
                        } else if (product?.images?.[1]?.originalSrc) {
                          image = product?.images?.[1]?.originalSrc;
                        }
                      } else {
                        // else the user has selected something and we should change the image to suit
                        const colourKey = addEnabledProducts[product.handle];
                        let fallbackImage;

                        imageType = `fluid`;

                        if (product?.availableColours?.[0]) {
                          product.availableColours.forEach(
                            ({ colourRef, gallery }) => {
                              if (image) {
                                return;
                              }

                              if (
                                !fallbackImage &&
                                gallery?.[0]?.asset?.fluid
                              ) {
                                fallbackImage = gallery[0].asset.fluid;
                              }

                              if (
                                colourRef?.title === colourKey &&
                                gallery?.[0]
                              ) {
                                image = gallery[0].asset.fluid;
                              }
                            }
                          );
                        }

                        if (!image) {
                          image = fallbackImage;
                        }
                      }

                      const imageJSX = (
                        <Link
                          to={`/products/${product.handle}`}
                          className="product-grid__item__image relative block rounded-edge overflow-hidden"
                        >
                          {(imageType === `fluid` && (
                            <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0 gpu">
                              <Img
                                className="w-full h-full relative object-cover"
                                fluid={image}
                                alt={product.handle}
                              />
                            </figure>
                          )) || (
                            <img
                              className="w-full absolute transform-center"
                              src={image}
                              alt={product.handle}
                            />
                          )}
                        </Link>
                      );

                      const variantSelector = (
                        <>
                          <div className="w-full flex flex-wrap mt-v1 xs:mt-4 mb-v1 xs:mb-4">
                            {colourButtonJSX}
                          </div>

                          {(!addEnabledProducts?.[product.handle] && (
                            <Link to={`/products/${product.handle}`}>
                              <Button
                                className="w-full relative block"
                                color="hit-pink"
                                transparent
                              >
                                <span className="f-button">View Product</span>
                              </Button>
                            </Link>
                          )) || (
                            <div className="products-page__hover-tray animation-appear w-full relative block">
                              <div className="products-page__hover-tray__shelf w-full absolute xs:relative top-0 xs:top-auto right-0 xs:right-auto bottom-0 xs:bottom-auto left-0 xs:left-auto z-10 flex justify-between xs:mb-2">
                                {sizeButtonJSX}
                              </div>

                              {(device === `desktop` && (
                                <Button
                                  className="products-page__hover-tray__button w-full relative block pointer-events-none"
                                  color="hit-pink"
                                  transparent
                                >
                                  <span className="f-button">Add to bag</span>
                                </Button>
                              )) || (
                                <Link to={`/products/${product.handle}`}>
                                  <Button
                                    className="products-page__hover-tray__button w-full relative block pointer-events-none"
                                    color="hit-pink"
                                    transparent
                                  >
                                    <span className="f-button">
                                      View product
                                    </span>
                                  </Button>
                                </Link>
                              )}
                            </div>
                          )}
                        </>
                      );

                      //

                      let innerJSX = (
                        <>
                          {(device === `desktop` && imageJSX) || (
                            <>{!isFeature && imageJSX}</>
                          )}

                          <article className="mt-v05 xs:mt-5">
                            <header className="w-full relative pt-v1">
                              {device !== `desktop` && (
                                <h2 className="mb-v1 xs:mb-3 f5 font-bold">
                                  {product.title}
                                </h2>
                              )}

                              <div className="flex items-center">
                                {product?.shopifyProduct?.variants?.[0]
                                  ?.price && (
                                  <h2 className="b1">
                                    ${product.shopifyProduct.variants[0].price}
                                  </h2>
                                )}

                                {product?.packSize && (
                                  <div className="relative flex items-center justify-center ml-3 pt-2 pr-2 xs:pr-4 pb-1 xs:pb-1 pl-2 xs:pl-4 border-black border-2 overflow-hidden rounded-full bg-hit-pink">
                                    <span
                                      className={`block ${
                                        device === `desktop` ? `caption` : `b2`
                                      }`}
                                    >
                                      {product.packSize}
                                    </span>
                                  </div>
                                )}
                              </div>
                            </header>

                            {device !== `desktop` && isFeature && (
                              <>
                                <h3 className="w-full mt-v1 xs:mt-4 b2">
                                  {product.tagline}
                                </h3>

                                <div className="mt-6 mb-4">{imageJSX}</div>
                              </>
                            )}

                            {variantSelector}
                          </article>
                        </>
                      );

                      if (device === `desktop` && isFeature) {
                        innerJSX = (
                          <div className="relative flex">
                            <Link
                              to={`/products/${product.handle}`}
                              className="product-grid__item__image--feature w-2/3 relative block rounded-edge overflow-hidden mr-v1"
                            >
                              {(imageType === `fluid` && (
                                <figure className="w-full h-full absolute top-0 right-0 bottom-0 left-0 gpu">
                                  <Img
                                    className="w-full h-full relative object-cover"
                                    fluid={image}
                                    alt={product.handle}
                                  />
                                </figure>
                              )) || (
                                <img
                                  className="w-full absolute transform-center"
                                  src={image}
                                  alt={product.handle}
                                />
                              )}
                            </Link>

                            <article className="w-1/3 flex flex-col justify-between mt-v05 xs:mt-5 pl-v1">
                              <header>
                                <h2 className="mb-v1 f5 font-bold">
                                  {product.title}
                                </h2>

                                <div className="relative flex items-center">
                                  {product?.shopifyProduct?.variants?.[0]
                                    ?.price && (
                                    <h2 className="b1">
                                      $
                                      {product.shopifyProduct.variants[0].price}
                                    </h2>
                                  )}

                                  {product?.packSize && (
                                    <div className="relative flex items-center justify-center ml-3 pt-2 pr-2 xs:pr-4 pb-1 xs:pb-1 pl-2 xs:pl-4 border-black border-2 overflow-hidden rounded-full bg-hit-pink">
                                      <span
                                        className={`block ${
                                          device === `desktop`
                                            ? `caption`
                                            : `b2`
                                        }`}
                                      >
                                        {product.packSize}
                                      </span>
                                    </div>
                                  )}
                                </div>

                                <h3 className="w-full mt-v1 b2 xs:mt-2">
                                  {product.tagline}
                                </h3>
                              </header>

                              <div className="relative mt-v2 xs:mt-4">
                                {variantSelector}
                              </div>
                            </article>
                          </div>
                        );
                      }

                      return (
                        <div
                          key={key}
                          className={`${
                            isFeature ? `w-full` : `w-1/3`
                          } xs:w-full relative mb-v2 xs:mb-16 pl-v1 xs:pl-0 pr-v1 xs:pr-0 pb-v1 xs:pb-0`}
                        >
                          {innerJSX}
                        </div>
                      );
                    })}
                  </section>
                );
              })}
            </div>
          </div>
        </section>

        <GivePaireCTA />
      </Layout>

      <Footer />
    </>
  );
};

export default ProductsPage;

export const query = graphql`
  query ProductsPage {
    sanityProductsPage {
      title
      jumpTargets
    }

    allSanityProduct {
      edges {
        node {
          title
          handle
          priority
          tagline
          productType
          packSize

          image {
            asset {
              fluid(maxWidth: 768) {
                ...GatsbySanityImageFluid
              }
            }
          }

          availableColours {
            colourRef {
              hexes
              title
            }

            gallery {
              asset {
                fluid(maxWidth: 400) {
                  ...GatsbySanityImageFluid
                }
              }
              altText
            }
          }

          shopifyProduct {
            id
            handle
            title
            description
            images {
              originalSrc
            }
            productType
            vendor
            variants {
              id
              sku
              title
              image {
                originalSrc
              }
              price
              selectedOptions {
                name
                value
              }
            }
          }
        }
      }
    }

    allSanityColourMap {
      edges {
        node {
          hexes
          title
        }
      }
    }
  }
`;
