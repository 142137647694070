import React from "react";
import PropTypes from "prop-types";

const SquiggleLine = ({ className, color }) => (
  <svg className={className} viewBox="0 0 319 10" fill="none">
    <path
      d="M1 5C11.5667 -0.333333 22.1333 -0.333333 32.7 5C43.2667 10.3333 53.8333 10.3333 64.4 5C74.9667 -0.333333 85.5333 -0.333333 96.1 5C106.667 10.3333 117.233 10.3333 127.8 5C138.367 -0.333333 148.933 -0.333333 159.5 5C170.067 10.3333 180.633 10.3333 191.2 5C201.767 -0.333333 212.333 -0.333333 222.9 5C233.467 10.3333 244.033 10.3333 254.6 5C265.167 -0.333333 275.733 -0.333333 286.3 5C296.867 10.3333 307.433 10.3333 318 5"
      stroke={color}
    />
  </svg>
);

SquiggleLine.defaultProps = {
  className: ``,
  color: `black`
};

SquiggleLine.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string
};

export default SquiggleLine;
